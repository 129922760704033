<template>
  <Head :data="data"/>

  <div style="padding-top: 46px;padding-bottom: 30px">
    <!--标题、发布时间、浏览量-->
    <div style="text-align: center;">
      <div style="margin:10px 20px 0;font-weight: bold">{{content.title}}</div>
      <div style="font-size: 14px; color:#757c84;margin: 8px 0">
        发布时间：{{content.time}} <span style="margin-left:10px;">浏览：{{content.clicks}}</span> 
      </div>

      <van-divider  />

    </div>
    <!--内容-->
    <div class="content" v-html="content.content"></div>

    <van-divider  />

    <!-- 上一篇 下一篇 -->
    <div style="text-align: justify; width: 100%;font-size: 14px;padding: 5px 0;">
      <span style="display: inline-block; float: left;padding-left: 5px;">
        <a style="color:#409eff"  v-if="contentPrev!=null" @click="toContentPrevNext(contentPrev.id, channelName, channelId,)">上一篇：{{strUtil.cutTitle(contentPrev.title, 10)}}</a>
        <a style="color:#969799"  v-else>没有上一篇了</a>
        </span>
      <span style="display: inline-block; float: right; text-align: right;padding-right: 5px;">
        <a style="color:#409eff"  v-if="contentNext!=null" @click="toContentPrevNext(contentNext.id, channelName, channelId,)">下一篇：{{strUtil.cutTitle(contentNext.title, 10)}}</a>
        <a style="color:#969799" v-else>没有下一篇了</a>
      </span>
    </div>

  </div>
</template>

<script>
import {reactive, ref, onMounted, watch} from 'vue'
import { useRoute, useRouter,onBeforeRouteUpdate } from 'vue-router';

import Head from '@/components/h5/Head'

import contentApi from "@/api/info/content";
import strUtil from "@/utils/strUtil";
import replaceEditorImgURL from '@/utils/replaceEditorImgURL'

export default {
  components:{
    Head
  },
  watch: {
    // 利用watch方法检测路由变化
    '$route': function (to, from) {
      if(to.path == '/h5/content') {
          this.data.title = this.$route.query.channelName;
          this.channelId = this.$route.query.channelId;
          this.channelName = this.$route.query.channelName;
          this.contentId = this.$route.query.contentId;
          this.queryDetailsContent();
      }
    }
  },
  setup(){
    const route = useRoute();
    const router = useRouter();

    const data = ref({
      sign: true,
      title: '内容详情',
      back: true
    });
    const channelId = ref('');
    const channelName = ref('');
    const contentId = ref('');
    const content = ref({});
    const contentPrev = ref({id:'', title:''})
    const contentNext = ref({id:'', title:''})
    
    // 新增点击量
    const addClicks = () =>{
      contentApi.addClicksContent(route.query.contentId);
    };

    // 查询详情内容
    const queryDetailsContent = ()=>{
      contentApi.queryDetailsContent(contentId.value, channelId.value).then(res => {
          content.value =res.data.data.data;
          if(res.data.data.data.content != null) {
            content.value.content = replaceEditorImgURL.toReplaceEditorImgURL(res.data.data.data.content);
          }
          contentPrev.value = res.data.data.contentPrev;
          contentNext.value = res.data.data.contentNext;
      })
    };

    //上一篇下一篇
    const toContentPrevNext = (contentId, channelName, channelId)=>{
        router.push({ path: '/h5/content',
        query: {
          contentId: contentId,
          channelId: channelId,
          channelName: channelName
        }})
        addClicks();
        //返回顶部
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0;
    };

    onMounted(()=> {
      data.value.title = route.query.channelName;
      channelId.value = route.query.channelId;
      channelName.value = route.query.channelName;
      contentId.value = route.query.contentId;
      queryDetailsContent();
      addClicks();
    });

    return{
      data,
      content,
      strUtil,
      addClicks,
      contentId,
      channelId,
      contentPrev,
      contentNext,
      channelName,
      toContentPrevNext,
      queryDetailsContent,
    }

  }
}
</script>

<style scoped>
.nav{
  top: 0;
  width: 100%;
  z-index: 100;
  position: fixed;
  padding-left: 0px;
  
}
.van-nav-bar{
  background-color: #409eff;
}
:deep().van-nav-bar__title, :deep().van-nav-bar .van-icon{
  color: white;
}

.van-divider{
  margin: 5px;
}

.content{
  margin: 0 10px;
}
.content p{
  line-height: 30px;
}
</style>